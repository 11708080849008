<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          CARGANDO DATA...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <div>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-dialog
          v-model="dialogRegistros"
          width="1200"
          persistent
        >
          <v-card>
            <v-card-title class="text-h3 grey lighten-2">
              Datos del usuario
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col
                  v-if="stateClave"
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="idUsuario"
                    label="Usuario"
                    :disabled="actualizar || stateClave"
                    :rules="userRules"
                  />
                </v-col>
                <v-col
                  v-if="!actualizar || stateClave"
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="claveUsuario"
                    label="Clave"
                    type="password"
                  />
                </v-col>
                <v-col
                  v-if="!stateClave"
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="nombreUsuario"
                    label="Nombre"
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  v-if="!stateClave"
                  cols="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="juzgadoSeleccionado"
                    :items="itemsJuzgados"
                    item-text="nombre"
                    item-value="id_juzgado"
                    label="Juzgado"
                    return-object
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  v-if="!stateClave"
                  cols="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="tipoUsuario"
                    :items="itemsUsuarios"
                    item-text="nombre"
                    item-value="id"
                    label="Tipo de usuario"
                    return-object
                    :rules="regla"
                  />
                </v-col>
                <v-col
                  v-if="actualizar || !stateClave"
                  cols="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="estadoUsuario"
                    :items="itemsEstados"
                    item-text="nombre"
                    item-value="id"
                    label="Estado"
                    :rules="regla"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="cerrarDialog()"
              >
                Cerrar
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="registrarUsuario()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </div>
    <v-row v-if="!carga">
      <v-col
        cols="12"
        lg="12"
      >
        <base-material-card
          icon="mdi-account-box-multiple-outline"
          color="primary"
          title="Administración de usuarios"
        >
          <v-card-title>
            <v-btn
              class="ma-5 md-round"
              rounded
              medium
              color="green"
              @click="abrirDialog()"
            >
              <v-icon>
                mdi-plus-box-outline
              </v-icon>
            </v-btn>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-auto"
              label="Buscar..."
              hide-details
              single-line
              style="max-width: 250px;"
            />
          </v-card-title>

          <v-data-table
            :headers="headersT"
            :items="items"
            :search="search"
            dense
            class="elevation-1"
            fixed-header
            :loading="carga"
            loading-text="Cargando, por favor espere"
            :footer-props="{
              'items-per-page-options': [25, 50, -1],
              'items-per-page-text': 'Mostrando:',
              'items-per-page-all-text': 'Todos'
            }"
            no-data-text="No hay datos registrados"
            no-results-text="No se encontraron resultados"
          >
            <template #[`item.tipo_usuario`]="{ item }">
              <v-icon
                v-if="item.tipo_usuario === 'E'"
                color="green"
              >
                mdi-file-excel
              </v-icon>
              <v-icon
                v-else
                color="blue"
              >
                mdi-gavel
              </v-icon>
              {{ item.tipo_usuario === 'E' ? 'ESTADÍSTICA' : 'JUEZ' }}
            </template>
            <template #[`item.estado`]="{ item }">
              <v-icon
                v-if="item.estado === 1"
                color="green"
              >
                mdi-check-decagram
              </v-icon>
              <v-icon
                v-else
                color="red"
              >
                mdi-close-circle
              </v-icon>
              {{ item.estado === 1 ? 'ACTIVO' : 'DESACTIVADO' }}
            </template>
            <template #[`item.editar`]="{ item }">
              <v-btn
                color="green"
                fab
                x-small
                dark
                @click="editarRegistro(item)"
              >
                <v-icon>mdi-pencil-box-multiple</v-icon>
              </v-btn>
              <v-btn
                color="orange"
                fab
                x-small
                dark
                @click="cambiarClave(item)"
              >
                <v-icon>mdi-lock</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'RegistroUsuarios',
    data () {
      return {
        headersT: [
          {
            text: 'Editar',
            value: 'editar',
          },
          {
            text: 'Usuario',
            value: 'id_usuario',
          },
          {
            text: 'Juzgado',
            value: 'juzgado',
          },
          {
            text: 'Nombre',
            value: 'nombre',
          },
          {
            text: 'Tipo de usuario',
            value: 'tipo_usuario',
          },
          {
            text: 'Estado',
            value: 'estado',
          },
        ],
        itemsEstado: [{
                        id: '1',
                        nombre: 'ACTIVO',
                      },
                      {
                        id: '99',
                        nombre: 'DESACTIVADO',
                      }],
        items: [],
        carga: true,
        dialogRegistros: false,
        search: '',
        actualizar: false,
        estadoSeleccionado: '',
        idSeleccionado: '',
        urlUsuario: '',
        regla: [(v) => !!v || 'Campo requerido'],
        itemsJuzgados: [],
        tipoCompetencia: '',
        itemsEstados: [
          { id: 1, nombre: 'ACTIVO' },
          { id: 2, nombre: 'DESACTIVADO' },
        ],
        itemsUsuarios: [
          { id: 'E', nombre: 'ESTADÍSTICA' },
          { id: 'J', nombre: 'JUEZ' },
        ],
        juzgadoSeleccionado: '',
        idUsuario: '',
        nombreUsuario: '',
        estadoUsuario: '',
        tipoUsuario: '',
        claveUsuario: '',
        stateClave: false,
        headers: { headers: { 'access-token': localStorage.getItem('tokenOdajup') } },
        userRules: [
          value => {
            if (!this.actualizar) {
              if (value && this.checkUser(value)) return true
              return 'Ingrese un usuario válido'
            }
          },
        ],
      }
    },
    created () {
      // console.log('"79212984\t1\tMONTOYA\tESPINOZA\t\tZAHIR FERNANDO\t13\t02\t01\t000\tLAMBAYEQUE\tFERRE�AFE\tFERRE�AFE\t\tSOLTERO\tILETRADO/SIN INSTRUCCION\t\tMASCULINO\t\t\t13\t01\t01\t000\tLAMBAYEQUE\tCHICLAYO\tCHICLAYO\t\t21/07/2015\tD.N.I./LIB.ELECTORIAL\t46796794\tFERNANDO ALBERTO\tD.N.I./LIB.ELECTORIAL\t47443856\tMARICRUZ DEL ROSARIO\t24/07/2015\t12/08/2015\t\tSUFRAG�\t12/08/2023\tNINGUNA\t\tCALLE LAS CASUARINAS MZ.B LT.16 P.JOVEN') // const headers = { headers: { 'access-token': localStorage.getItem('tokenOdajup') } }
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioOdajup'))
      // console.log(this.dataUsuario)
      if (!this.dataUsuario) {
        this.$router.replace('/login')
      } else {
        this.imgLogo = 'assets/favicon.png'
        this.validarToken()
      }
    },
    methods: {
      validarToken () {
        axios
          .get(`${localStorage.getItem('urlApiOdajup')}v0/token`, this.headers)
          .then((r) => {
            if (r.data.message !== true) {
              this.$router.replace('/cerrar')
            } else {
              this.leerUsuarios()
              this.leerJuzgados()
              this.carga = false
            }
          })
          .catch((err) => {
            alert('Servicio no disponible' + err)
            this.carga = false
            this.$router.replace('/cerrar')
          })
      },
      abrirDialog () {
        this.dialogRegistros = true
        this.juzgadoSeleccionado = this.dataUsuario.id_juzgado
      },
      visualizarCampos () {
        this.tipoRegistro = this.tipoCompetencia.tipo_competencia.charAt(0)
      },
      cambiarClave (item) {
        this.idUsuario = item.id_usuario
        this.stateClave = true
        this.dialogRegistros = true
      },
      leerUsuarios () {
        // var usuario = this.dataUsuario.tipo_usuario === 'E' ? '' : this.dataUsuario.id_usuario
        axios
          .get(`${localStorage.getItem('urlApiOdajup')}v0/usuarios/`, this.headers)
          .then((response) => {
            // console.log(response)
            this.items = response.data.data
            this.carga = false
            this.cargaPartes = false
          })
          .catch((e) => {
            console.log(e) // eslint-disable-line
            alert('NO SE PUEDE CONECTAR AL SERVICIO WEB')
            this.carga = false
          })
      },
      leerJuzgados () {
        axios
          .get(`${localStorage.getItem('urlApiOdajup')}v0/juzgados/`, this.headers)
          .then((response) => {
            this.itemsJuzgados = response.data.data
          })
          .catch((e) => {
            console.log(e) // eslint-disable-line
            alert('NO SE PUEDE CONECTAR AL SERVICIO WEB')
            this.carga = false
          })
      },
      editarRegistro (item) {
        this.idUsuario = item.id_usuario
        this.nombreUsuario = item.nombre
        this.tipoUsuario = this.itemsUsuarios.filter(tipo => tipo.id === item.tipo_usuario)[0]
        this.estadoUsuario = this.itemsEstados.filter(tipo => tipo.id === item.estado)[0]
        this.juzgadoSeleccionado = this.itemsJuzgados.filter(tipo => tipo.id_juzgado === item.id_juzgado)[0]
        this.actualizar = true
        this.dialogRegistros = true
      },
      cerrarDialog () {
        this.tipoRegistro = null
        this.actualizar = false
        this.dialogRegistros = false
        this.$refs.form.reset()
      },
      checkUser (user) {
        if (this.items.filter(item => item.id_usuario === user).length === 1) {
          return false
        } else {
          return true
        }
      },
      keepSaving () {
        this.tipoRegistro = null
        this.actualizar = false
      },
      registrarUsuario () {
        const data = {
          id_usuario: this.idUsuario,
          id_juzgado: this.juzgadoSeleccionado.id_juzgado,
          nombre: this.nombreUsuario,
          estado: this.estadoUsuario.id,
          clave: this.claveUsuario,
          tipo_usuario: this.tipoUsuario.id,
        }
        // console.log(data)
        if (this.$refs.form.validate()) {
          this.actualizar === false ? this.loadingText = 'REGISTRANDO USUARIO...' : this.loadingText = 'ACTUALIZANDO USUARIO...'
          this.carga = true
          if (!this.actualizar) {
            axios
              .post(`${localStorage.getItem('urlApiOdajup')}v0/usuario/`, data, this.headers)
              .then((r) => {
                // console.log(r)
                if (r.data.message === true) {
                  alert(this.loadingText)
                  this.carga = false
                  this.leerUsuarios()
                  this.cerrarDialog()
                } else {
                  alert('No se pudo completar el registro.')
                  this.carga = false
                }
              })
              .catch((err) => {
                alert('Servicio no disponible' + err)
                this.carga = false
              })
          } else {
            axios
              .put(`${localStorage.getItem('urlApiOdajup')}v0/usuario/`, data, this.headers)
              .then((r) => {
                // console.log(r)
                if (r.data.message === true) {
                  alert(this.loadingText)
                  this.carga = false
                  this.leerUsuarios()
                  this.cerrarDialog()
                } else {
                  alert('No se pudo completar el registro.')
                  this.carga = false
                }
              })
              .catch((err) => {
                alert('Servicio no disponible' + err)
                this.carga = false
              })
          }
        }
      },
      formatDate (template, date) {
        const specs = 'YYYY:MM:DD:HH:mm:ss'.split(':')
        date = new Date(
          date || Date.now() - new Date().getTimezoneOffset() * 6e4,
        )
        return date
          .toISOString()
          .split(/[-:.TZ]/)
          .reduce(
            (template, item, i) =>
            // eslint-disable-line
              template.split(specs[i]).join(item),
            template,
          )
      },
    },
  }
</script>
